import { extension } from 'mime-types';

//prettier-ignore
export const createPath = (baseFolder: string) => 
  (...segments: (string | number)[]) => [baseFolder, ...segments].join('/');

export const createUrl = (storage: string) => (path: string) =>
  `${storage}/${path}`;

export const addExtension = (filename: string, mimeType: string) =>
  `${filename}.${extension(mimeType)}`;
