import { call, put, select, fork } from 'redux-saga/effects';
import { TeamFiltered, User } from '@distribute/shared/types';
import { actions } from '../reducer';
import { teamsApi } from '../../../../shared/api';
import { getQueryParam, logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { loadTeamRelatedData } from './loadTeamRelatedData';
import { foldersModel } from '../../../folders';
import { pagesModel } from '../../../pages';
import { handleWhenUserHasNoTeams } from './handleWhenUserHasNoTeams';
import { QUERY_PARAM_CURRENT_TEAM_ID } from '../../config';
import { getCurrentTeam } from '../../lib';
import { authUserModel } from '../../../../entities/auth-user';
import { getCallTranscripts } from '../../../../entities/gong/model/sagas';

export function* setTeamsData(acceptedTeam?: TeamFiltered) {
  try {
    yield put(actions.setTeamsStatus('pending'));
    const currentUser: User = yield select(
      authUserModel.selectors.selectUserWithError
    );
    const teams: TeamFiltered[] = yield call(teamsApi.getTeams);
    const currentTeamId = Number(getQueryParam(QUERY_PARAM_CURRENT_TEAM_ID));
    const currentTeam = getCurrentTeam({
      teams,
      currentUser,
      acceptedTeam,
      currentTeamId,
    });

    if (!currentTeam) {
      yield call(handleWhenUserHasNoTeams);
      yield put(actions.setTeamsStatus('success'));
      yield put(foldersModel.actions.setFoldersStatus('success'));
      yield put(pagesModel.actions.setPagesStatus('success'));

      return;
    }

    yield put(actions.setTeams(teams));
    yield put(actions.setCurrentTeam(currentTeam));
    yield fork(getCallTranscripts);
    yield call(loadTeamRelatedData);

    yield put(actions.setTeamsStatus('success'));
  } catch (e: unknown) {
    logger.error(e);
    yield put(actions.setTeamsStatus('error'));
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to display team`)
      )
    );
  }
}
