import { VideoQuality } from '@distribute/shared/types';
import { addExtension, createPath, createUrl } from './video-shortcut';

/* Path to the files:

1. record/teamId:/prefix:/video.webm
2. record/teamId:/prefix:/[720p/1080p]/video.mp4

*/

type BaseParams = {
  teamId: number;
  prefix: string;
};

type UploadParams = BaseParams & {
  uploadId: string;
};

type VideoQualityParams = BaseParams & {
  videoQuality: VideoQuality;
};

export const createVideoRecordShortcut = (storage: string) => {
  const url = createUrl(storage);
  const path = createPath('record');

  return {
    createPathToFolder: ({ teamId, prefix }: BaseParams) =>
      path(teamId, prefix),

    createPathToWebm: ({ teamId, prefix }: BaseParams) =>
      path(teamId, prefix, addExtension('video', 'video/webm')),

    createPathToWebmURL: ({ teamId, prefix }: BaseParams) =>
      url(path(teamId, prefix, addExtension('video', 'video/webm'))),

    createPathToUploadWebmURL: ({ teamId, prefix, uploadId }: UploadParams) =>
      `${url(
        path(teamId, prefix, addExtension('video', 'video/webm'))
      )}?upload_id=${uploadId}`,

    createPathToMp4: ({ teamId, prefix, videoQuality }: VideoQualityParams) =>
      path(teamId, prefix, videoQuality, addExtension('video', 'video/mp4')),

    createPathToMp4URL: ({
      teamId,
      prefix,
      videoQuality,
    }: VideoQualityParams) =>
      url(
        path(teamId, prefix, videoQuality, addExtension('video', 'video/mp4'))
      ),
  };
};

export type VideoRecordShortcut = ReturnType<typeof createVideoRecordShortcut>;
