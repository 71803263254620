import { MuxMetadata } from './mux';
import { UserPermissionToVideoRecord } from './user-permission-to-video-record';

export enum VideoRecordStatus {
  UPLOADING = 'uploading',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum VideoRecordStatusVerification {
  IDLE = 'idle',
  CANCELLED_DUE_MAX_DURATION_LIMIT = 'cancelled_due_max_duration_limit',
  CANCELLED_DUE_MAX_SIZE_LIMIT = 'cancelled_due_max_size_limit',
  PASSED = 'passed',
  FAILED = 'failed',
}

export enum VideoRecordStatusConvert {
  IDLE = 'idle',
  CONVERTING = 'converting',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum VideoRecordStatusDeleteRequest {
  IDLE = 'idle',
  DELETING = 'deleting',
  FAILED = 'failed',
  COMPLETED = 'completed',
}

export enum VideoRecordStatusMp4 {
  IDLE = 'idle',
  PREPARING = 'preparing',
  FAILED = 'failed',
  COMPLETED = 'completed',
}

export enum VideoRecordTeamMembersPermission {
  VIEW = 'view',
  EDIT = 'edit',
  INVITE_ONLY = 'inviteOnly',
}

export enum VideoQuality {
  HD = '720p',
  FULLHD = '1080p',
}

export type VideoRecordMetadataFormat = {
  format: {
    size: string;
    duration: string;
  };
};

export type VideoRecordMetadataMP4 = {
  createdAt?: string;
  completedAt?: string;
  input?: VideoRecordMetadataFormat;
  output?: VideoRecordMetadataFormat;
};

export type VideoRecordMetadata = {
  mp4: VideoRecordMetadataMP4;
  mux: MuxMetadata;
};

export type VideoRecord = {
  id: string;
  videoQuality: VideoQuality;
  videoSessionLimit: number;
  prefix: string;
  name: string;
  plan: string; // equal to SubscriptionPlanName
  teamMembersPermission: VideoRecordTeamMembersPermission;
  userPermissionToVideoRecords: UserPermissionToVideoRecord[];
  teamId: number;
  uploadId: string;
  metadata: VideoRecordMetadata;
  mp4JobId: string | null;
  muxId: string | null;
  playbackId: string | null;
  status: VideoRecordStatus;
  statusMp4: VideoRecordStatusMp4;
  statusConvert: VideoRecordStatusConvert;
  statusDeleteRequest: VideoRecordStatusDeleteRequest;
  statusVerification: VideoRecordStatusVerification;
  createdAt: Date;
  updatedAt: Date;
};
